<!-- eslint-disable vue/prop-name-casing -->
<template>
  <div
    ref="compRef"
    class="image-gallery-container"
    :class="{ 'light-bg': isLightBg, 'no-header': !header_content }"
    :style="componentStyle"
  >
    <div v-if="header_content" class="content" v-html="header_content" />
    <CustomButton
      v-if="btn"
      v-show="!shopifyBuyBtn"
      ref="buttonEl"
      :style-color="btn?.style_color"
      :style-name="btn?.type"
      :href="href"
      :to="internalLink"
    >
      {{ btn.button_text }}
    </CustomButton>
    <div v-if="shopifyBuyBtnContainerId" :id="shopifyBuyBtnContainerId" class="sbbtn"></div>
    <ShopifyBuyButton
      v-if="shopifyBuyBtnContainerId"
      :container-id="shopifyBuyBtnContainerId"
      :shopify-buy-btn="shopifyBuyBtn"
      :btn-text="btn.button_text"
      :btn-class="buttonEl?.computedClass?.join(' ').replace(/\s+/g, ' ').trim()"
    />

    <ScrollableContainer>
      <div
        v-for="item in items"
        :key="item.title"
        class="flex-col item-container"
        :class="[image_orientation === '3:2' ? 'vertical' : 'square', image_size]"
      >
        <!-- @todo @test -- next sprint -->
        <CloudinaryImage
          :public-id="item.image?.[0]?.public_id"
          :options="imgOptions"
          :alt="item.image[0]?.name || ''"
          :style="image_background_color ? `background-color: ${image_background_color}` : ''"
        />
        <div v-if="item.title" class="title">{{ item.title }}</div>
        <div v-if="item.description" class="desc">{{ item.description }}</div>
      </div>
    </ScrollableContainer>
  </div>
</template>

<script setup lang="ts">
import { isEmpty } from 'lodash-es';
import { AssetCloudinary, ButtonCS } from '~/types/contentstack';
import { extractInternalLink } from '~/util/contentstack/csHelpers';
import { generateIdNoDate } from '~/util/generateId';

const localePath = useLocalePath();

const props = defineProps({
  items: {
    type: Array as () => {
      title: string;
      description: string;
      image: AssetCloudinary[];
    }[],
    required: true,
  },
  // eslint-disable-next-line vue/require-default-prop
  button: {
    type: Object as () => ButtonCS,
    required: false,
  },
  /* eslint-disable vue/prop-name-casing */
  image_orientation: {
    type: String,
    default: '3:2',
  },
  image_size: {
    type: String,
    default: 'large',
  },
  image_background_color: {
    type: String,
    default: undefined,
  },
  header_content: {
    type: String,
    required: false,
    default: '',
  },
  background_color: {
    type: Object as () => {
      color: string;
    },
    required: false,
    default: () => {
      return {
        color: '',
      };
    },
  },
  /* eslint-enable vue/prop-name-casing */
});

const compRef = ref(null);
const buttonEl = ref(null);

const isLightBg = computed(() => {
  return ['#ffffff', '#f5f2ef', '#F9B700'].includes(props.background_color.color);
});
const btn = computed<any>(() => (isEmpty(props.button?.button_text) ? undefined : props.button));
const shopifyBuyBtn = computed<any>(() =>
  btn.value && props.button?.shopify_buy_button?.enabled ? props.button?.shopify_buy_button : undefined
);
const shopifyBuyBtnContainerId = computed<any>(() =>
  shopifyBuyBtn.value && compRef.value && buttonEl.value ? generateIdNoDate(compRef.value.class) : undefined
);
const href = computed<string | undefined>(() => props.button?.external_link?.href);
const internalLink = computed<string | undefined>(() => {
  const url = extractInternalLink(props.button?.internal_linkConnection.edges?.[0]) || undefined;
  const localizedUrl = url && localePath ? localePath(url) : url;
  // TODO - add logic for linking to different types of pags (blogs, etc)
  return localizedUrl;
});
const componentStyle = computed(() => {
  return {
    ...(props.background_color?.color ? { backgroundColor: props.background_color?.color } : {}),
  };
});

const imgOptions = computed(() => {
  const width = 280;
  const height = props.image_orientation === '3:2' ? 420 : 280;
  return { width, height };
});
</script>

<style lang="scss" scoped>
.image-gallery-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  // padding: 3rem 1rem;
  // color: $color-neutral-white;
  @include local-mixins.desktop {
    // padding: 4rem 6.25rem 7.75rem 6.25rem;
  }
  &.light-bg {
    color: $color-neutral-black;
  }
  &.no-header {
    padding-top: 0;
  }
}
.content {
  // max-width: 405px;
  margin-bottom: 1.5rem;
  padding: 0.5rem;
}
.item-container {
  &.vertical {
    img {
      width: auto;
      height: 420px;
    }
    &.small {
      img {
        height: 320px;
      }
    }
  }
  &.square {
    img {
      width: 280px;
      height: 280px;
    }
  }
}
.title {
  margin-top: 1rem;
  font-size: 18px;
  font-weight: 600;
  line-height: 125%;
}
.desc {
  font-size: 14px;
  line-height: 125%;
}
.btn {
  width: fit-content;
  margin-top: -1rem;
  margin-bottom: 2rem;
}
.sbbtn {
  margin-bottom: 2rem;
}
</style>
